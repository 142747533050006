setTimeout(() => {
  const script = document.createElement('script');
  script.src = 'https://tools.luckyorange.com/core/lo.js?site-id=a3f13374';
  document.head.appendChild(script);

  if(document.querySelector('body').classList.contains('logged_in')) {
    window.LOQ = window.LOQ || [];
    window.LOQ.push(['ready', function (LO) {
      LO.$internal.ready('visitor').then(function() {
        console.log("LO identify")
        console.log(LO.visitor)
        LO.visitor.identify({name: gon.current_user.name, email: gon.current_user.email, phone: gon.current_user.phone, description: `${gon.current_user.company} - ${gon.current_user.industry}`})
      })
    }]);
  }
}, 5 * 1000)
